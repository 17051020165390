(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/request-idle-callback/assets/javascripts/request-idle-callback.js') >= 0) return;  svs.modules.push('/components/utils/request-idle-callback/assets/javascripts/request-idle-callback.js');
(function(window) {
  'use strict';

  window.requestIdleCallback =
    window.requestIdleCallback ||
    function(cb) {
      var start = Date.now();
      return setTimeout(function() {
        cb({
          didTimeout: false,
          timeRemaining: function() {
            return Math.max(0, 50 - (Date.now() - start));
          }
        });
      }, 1);
    };

  window.cancelIdleCallback =
    window.cancelIdleCallback ||
    function(id) {
      clearTimeout(id);
    };
})(window);


 })(window);